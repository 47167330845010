<template>
  <div>
    <b-overlay :show="isShowLoading">
      <b-card :title="$t('master.department.form')">

        <validation-observer ref="validateForm" #default="{invalid}">
          <b-form @submit.prevent="saveData">
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('offlineTraining.bookingDate') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('offlineTraining.bookingDate')" rules="required">
                    <flat-pickr v-model="offlineDate" class="form-control" :config="$store.state.main.datePickerConfig"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
              <b-col md="6">
                <b-form-group :label="$t('training.time') + '*'">
                <validation-provider #default="{ errors }" :name="$t('training.time')" rules="required">
                    <v-select v-model="offlineTime"
                              :options="optionTime"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
              <b-col md="6">
                <b-form-group :label="$t('offlineTraining.participant') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('offlineTraining.bookingDate')" rules="required|integer">
                    <b-form-input v-model="offlineParticipant" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-center">
                <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                  <b-spinner small v-if="isBtnDisabled"/>
                  <feather-icon icon="SaveIcon" v-else/>
                  {{ $t('general.btnSubmit') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BButton, BOverlay} from 'bootstrap-vue'
import moment from 'moment-timezone'
moment.tz.setDefault("Asia/Bangkok")
import {required, integer} from '@validations'
import masterMixins from "@/mixins/masterMixins"
import formMixins from "@/mixins/formMixins"
import {OfflineTrainingService} from "@/services"

const offlineTrainingService = new OfflineTrainingService()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    BOverlay,
  },
  mixins: [masterMixins, formMixins],
  async created() {
    this.offlineDate = moment().format('DD/MM/YYYY')
    this.optionTime = this.listTrainingTime()

    this.isShowLoading = false
    this.enabledCheckBackButton()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    departmentName: '',
    offlineDate: '',
    offlineTime: '',
    offlineParticipant: '',
    optionTime: [],
  }),
  methods: {
    saveData() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true
          let actionData = {
            offlineDate: this.offlineDate,
            offlineTime: this.offlineTime,
            offlineParticipant: this.offlineParticipant,
          }
          let saveResult = await offlineTrainingService.createData(actionData)

          this.isBtnDisabled = false

          if (saveResult.isSuccess) {
            this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
            await this.$router.push({name: 'pageOfflineTraining'})
          } else {
            this.$store.commit('main/setToastError', saveResult.data)
          }
        }
      })
    }
  }
}
</script>

<style>

</style>
